import { getLocalStorageItem } from '@snapchat/mw-common';
import type { FormBody } from '@snapchat/snap-design-system-marketing';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { type CSSProperties, type FC, useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../AppContext';
import { Anchor } from '../../../../components/Anchor';
import { CallToAction } from '../../../../components/CallToAction';
import { Form } from '../../../../components/Form/Form';
import { Image } from '../../../../components/Image';
import { useConsumerContext } from '../../../../components/useConsumerContext';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { renderRichTextWithElements } from '../../../../utils/renderText/renderRichText';
import type { ExperienceSiteData } from '../../types';
import { Sps2024Animation } from '../Sps2024Animation';
import {
  ctaCss,
  middleTextClassName as bottomTextClassName,
  middleTextClassName,
  primaryTextClassName as topTextClassName,
  regAddCalendarCss,
  registrationResultAnimationCss,
  registrationResultContainerCss,
  saveTheDateClassName,
  spsLogoCharCountCssVar,
  topPaddingCss,
  usernameFormTextClassName,
} from './Sps2024Confirmation.styles';
import type { Sps2024ConfirmationProps } from './Sps2024ConfirmationQuery';

const firstNameKeyRegex = /\{firstName\}|\{First_Name__c\}/;

export const Sps2024Confirmation: FC<Sps2024ConfirmationProps> = ({
  animationOverrideImage,
  personalizedTitle,
  fallbackTitle,
  topText,
  middleText,
  bottomText,
  personalizedAnimationTextLines,
  fallbackAnimationTextLines,
  showSaveTheDate,
  googleCalendarEventUrl,
  icsFile,
  callToAction,
  usernameFormText,
  usernameForm,
  registrationSource,
}) => {
  const siteData = useContext(AppContext).siteData as ExperienceSiteData;
  const setSiteData = useContext(AppContext).setSiteData;
  const { setHeaderBackgroundColorOverride, headerBackgroundColorOverride } =
    useContext(PageLayoutContext);

  const [isRetrievingFirstName, setIsRetrievingFirstName] = useState(true);
  const [firstName, setFirstName] = useState<string | undefined>();

  useEffect(() => {
    // Get the user's first name from site data or local storage to personalize the animation and title
    if (siteData.firstName) {
      setFirstName(siteData.firstName);
    } else if (registrationSource) {
      const localStorageObject = JSON.parse(getLocalStorageItem('experience-registration') ?? '{}');

      const capturedFields = localStorageObject?.[registrationSource.sys.id] as
        | Record<string, string>
        | undefined;

      const storedFirstName = (capturedFields?.firstName ?? capturedFields?.First_Name__c) as
        | string
        | undefined;

      setFirstName(storedFirstName);
    }

    setIsRetrievingFirstName(false);

    // The header background color can be overridden by the registration form, so we reset it
    // to white here to ensure logo/global-nav look good on the confirmation page
    // TODO: We can probably remove this if/when experience has a persistent navigation bar
    if (headerBackgroundColorOverride !== BackgroundColor.White) {
      setHeaderBackgroundColorOverride?.(BackgroundColor.White);
    }
  }, [
    headerBackgroundColorOverride,
    registrationSource,
    setHeaderBackgroundColorOverride,
    siteData.firstName,
  ]);

  const onFormSubmit = useCallback(
    (formBody: FormBody) => {
      setSiteData({
        ...siteData,
        snapchatUsername: formBody.snapchatUsername,
      });
    },
    [setSiteData, siteData]
  );

  // Get the registration token from the URL in order to authenticate the call from the username form
  const { getUrlParams } = useConsumerContext();
  const registrationToken = getUrlParams?.().registration_token;

  // Try to personalize the animation text with the user's first name if we have it and it's short enough
  const animationTextLines =
    firstName && firstName.length <= 8
      ? personalizedAnimationTextLines?.map((line: string) =>
          line.replace(firstNameKeyRegex, firstName.toLocaleUpperCase())
        )
      : fallbackAnimationTextLines;

  // Try to personalize the title with the user's first name if we have it
  const title = firstName
    ? personalizedTitle?.replace(firstNameKeyRegex, firstName)
    : fallbackTitle;

  // Length of the longest line of text in the animation will determine the width of the animation
  const longestLineLength = animationTextLines?.reduce(
    (val: number, current: string) => Math.max(val, current.length),
    0
  );

  // Animation and title can be personalized, so don't render until we've attempted to retrieve the
  // first name from either the site data (which is populated from Cvent) or local storage
  if (isRetrievingFirstName) return null;

  return (
    <div
      data-testid="sps-registration-result"
      className={registrationResultContainerCss}
      style={{ [spsLogoCharCountCssVar]: longestLineLength } as CSSProperties}
    >
      {animationOverrideImage ? (
        <Image {...animationOverrideImage} />
      ) : (
        <Sps2024Animation
          className={registrationResultAnimationCss}
          textLines={animationTextLines ?? []}
        />
      )}
      <h1 className={topPaddingCss}>{title}</h1>
      {topText && <div className={topTextClassName}>{renderRichTextWithElements(topText)}</div>}
      {middleText && (
        <div className={middleTextClassName}>{renderRichTextWithElements(middleText)}</div>
      )}
      {bottomText && (
        <div className={bottomTextClassName}>{renderRichTextWithElements(bottomText)}</div>
      )}
      {showSaveTheDate && (
        <div className={saveTheDateClassName}>
          <h1>Save the date</h1>
          <ul className={regAddCalendarCss}>
            <li>
              <Anchor href={googleCalendarEventUrl}>
                <img src="/gcal.png" />
              </Anchor>
            </li>
            <li>
              <Anchor href={icsFile?.url}>
                <img src="/ical.png" />
              </Anchor>
            </li>
            <li>
              <Anchor href={icsFile?.url}>
                <img src="/outlook.png" />
              </Anchor>
            </li>
          </ul>
        </div>
      )}
      {callToAction && <CallToAction className={ctaCss} {...callToAction} />}
      {/* If user has not given their username, show the username submission form if one is configured*/}
      {!siteData.snapchatUsername && usernameForm && (
        <>
          {usernameFormText && (
            <section className={usernameFormTextClassName}>
              {renderRichTextWithElements(usernameFormText)}
            </section>
          )}
          <Form
            {...usernameForm}
            callback={onFormSubmit}
            extraParams={{ registrationToken: registrationToken ?? '' }}
          />
        </>
      )}
    </div>
  );
};
