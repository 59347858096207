import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { ContentfulFormRow } from 'src/components/Form';

import { fragments as analyticsFragments } from '../../../../components/Analytics/query';
import type { AnalyticsDataProps } from '../../../../components/Analytics/types';
import { formRowFragment } from '../../../../components/Form';
import { fragments as imageFragments } from '../../../../components/Image/query';
import type { ImageDataProps } from '../../../../components/Image/types';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import {
  cventConnectorFragment,
  salesforceConnectorFragment,
  zkipsterConnectorFragment,
} from '../fragments';
import type { CventConnectorDataProps, SalesforceConnectorDataProps } from '../types';

const sps2024RegistrationFragment = gql`
  fragment ExperienceSps2024RegistrationFragment on ExperienceSps2024Registration {
    animationTextLines
    animationOverrideImage {
      ...ImageAll
    }
    leftSideText {
      json
    }
    leftSideBackgroundColor
    rightSideBackgroundColor
    formTitle
    unknownErrorText {
      json
    }
    formRowsCollection {
      items {
        ...FormRowAll
      }
    }
    formAnalytics {
      ...AnalyticsAll
    }
    connector {
      ... on SalesforceConnector {
        ...SalesforceConnectorAll
      }
      ... on CventConnector {
        ...CventConnectorAll
      }
      ... on ZkipsterConnector {
        ...ZkipsterConnectorAll
      }
    }
    confirmationSlug {
      ...ContentfulSysId
      slug
    }
    isClosed
    closedText {
      json
    }
    ...ContentfulSysId
  }
  ${imageFragments.all}
  ${formRowFragment}
  ${analyticsFragments.all}
  ${salesforceConnectorFragment}
  ${cventConnectorFragment}
  ${zkipsterConnectorFragment}
  ${contentfulSysIdFragment}
`;

export interface Sps2024RegistrationProps extends ContentfulTypedSysProps<'Sps2024Registration'> {
  animationTextLines?: string[];
  animationOverrideImage?: ImageDataProps;
  leftSideText?: RichText;
  leftSideBackgroundColor?: BackgroundColor;
  rightSideBackgroundColor?: BackgroundColor;
  formTitle?: string;
  unknownErrorText?: RichText;
  formRowsCollection?: Items<ContentfulFormRow>;
  formAnalytics?: AnalyticsDataProps;
  connector?: SalesforceConnectorDataProps | CventConnectorDataProps;
  confirmationSlug?: { slug: string };
  isClosed?: boolean;
  closedText?: RichText;
}

// =================================================================================================
// RegistrationBlock Query
// =================================================================================================

export const sps2024RegistrationQuery = gql`
  query Sps2024Registration($id: String!, $locale: String!, $preview: Boolean!) {
    experienceSps2024Registration(id: $id, locale: $locale, preview: $preview) {
      ...ExperienceSps2024RegistrationFragment
    }
  }
  ${sps2024RegistrationFragment}
`;

export interface Sps2024RegistrationQueryData {
  experienceSps2024Registration: Sps2024RegistrationProps;
}
